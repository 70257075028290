import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false
};

const SlideText = () => {
  return (
    <div className='main__header'>
      <Slider {...settings}>
        {/* <p>
          🔴 03/10/2023 - Kaiko Indices launches its first batch of Blue-chip Indices with Top 5, 10 and 15 available!
        </p> */}
        <p>
          🟢 Rates & Indices - Q2 2024 Rebalancing is available since June 24th.
        </p>
        <p>
          🟠 Rates & Indices - Q3 2024 Rebalancing is scheduled for September 23rd.
        </p>
      </Slider>
    </div>
  )
}

export default SlideText;