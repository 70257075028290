import React from 'react';

import ICONS from '../../assets/icons';

import './Links.scss';

const Links = () => {
  return (
    <ul className='links'>
      <li className='links__item'>
        <a target='_blank' rel="noreferrer" href='https://www.kaiko.com/products/rates-and-indices/kaiko-digital-asset-rates/reference-rates' className='links__item-link'>
          <span className='links__item-content'>
            <ICONS.asset />
            Reference Rates
          </span>
        </a>
      </li>

      <li className='links__item'>
        <a target='_blank' rel="noreferrer" href='https://www.kaiko.com/products/rates-and-indices/kaiko-digital-asset-rates/benchmark-reference-rates' className='links__item-link'>
          <span className='links__item-content'>
            <ICONS.exchange />
            Benchmark Reference Rates
          </span>
        </a>
      </li>

      <li className='links__item'>
        <a target='_blank' rel="noreferrer" href='https://marketing.kaiko.com/hubfs/Factsheets/Kaiko%20Benchmark%20Rates%20Rulebook%20-%20202212%20(1).pdf' className='links__item-link'>
          <span className='links__item-content'>
            <ICONS.asset />
            Rulebook Rates
          </span>
        </a>
      </li>

      <li className='links__item'>
      {  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
        <a target='_blank' rel="noreferrer" href='https://www.kaiko.com/products/rates-and-indices/indices' className='links__item-link'>
          <span className='links__item-content'>
            <ICONS.granular />
            Indices
          </span>
        </a>
      </li>

      <li className='links__item'>
        {  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
        <a target='_blank' rel="noreferrer" href='https://25446524.fs1.hubspotusercontent-eu1.net/hubfs/25446524/Factsheets/Kaiko%20Indices%20Rulebook.pdf' className='links__item-link'>
          <span className='links__item-content'>
            <ICONS.granular    />
            Rulebook Indices
          </span>
        </a>
      </li>

      <li className='links__item'>
        <a target='_blank' rel="noreferrer" href='https://www.kaiko.com/products/rates-and-indices/exchange-ranking' className='links__item-link'>
          <span className='links__item-content'>
            <ICONS.bitcoin />
            Exchange Ranking
          </span>
        </a>
      </li>
    </ul>
  )
}

export default Links;
