import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import classModifier from '../../utils/classModifier';

import './SmallTable.scss';
import SmallTableItem from './components/SmallTableItem';
import Spinner from '../Spinner/Spinner';


const SmallTable = ({ 
  tableTitle, 
  tableData, 
  isRatesTable, 
  dimWidth, 
  setTableData, 
  tableId,
  streamUpdates,
  sendJsonMessage,
  chartsDataValues
  }) => {

  const referenceRateData = tableData?.filter(([ name ]) => name.toUpperCase().startsWith('KK_BRR'));
  const priceRateData = tableData?.filter(([ name ]) => name.toUpperCase().startsWith('KK_RFR'));
  const indiceRateData = tableData?.filter(([ name ]) => !name.toUpperCase().startsWith('KK_BRR') && !name.toUpperCase().startsWith('KK_RFR'));

  return (
    <div className='small-table'>
      <h3 className='small-table__title'>{tableTitle}</h3>

      <Tabs className="small-table__tabs">
        {isRatesTable &&
          <div className="small-table__tabs-list-wrapper">
            <TabList className="small-table__tabs-list">
              <Tab className="small-table__tabs-item">Benchmark Reference Rate</Tab>
              <Tab className="small-table__tabs-item">Reference Rate</Tab>
            </TabList>
          </div>
        }
        <div className='small-table__table-wrapper'>
          <div className={classModifier('small-table__table-row', 'header')}>
            <div className={classModifier('small-table__table-col', 'ticker')}>Ticker</div>
            <div className={classModifier('small-table__table-col', 'value')}>Price</div>
            <div className={classModifier('small-table__table-col', 'growth')}>24h % Growth</div>
            {isRatesTable && false && <div className={classModifier('small-table__table-col', 'adtv')}>Rolling 24h % Vol. Chg.</div>}
            <div className={classModifier('small-table__table-col', 'exchange')}>{isRatesTable ? 'Exchanges' : 'Assets'}</div>
          </div>

          {isRatesTable
            ? 
              <>
                <TabPanel>
                  {tableData && streamUpdates
                    ? referenceRateData.length > 0 
                      ? <ul className='small-table__table'>
                          {referenceRateData.map((tableID, idx) => {
                            return (
                              <SmallTableItem 
                                key={idx} 
                                idx={idx} 
                                tableId={tableId}
                                dimWidth={dimWidth}
                                tableData={tableID}
                                streamUpdates={streamUpdates}
                                isRatesTable={isRatesTable}
                                setTableData={setTableData}
                                sendJsonMessage={sendJsonMessage}
                                chartsDataValues={chartsDataValues}
                              />
                            )})
                          }
                        </ul>
                      : <h2 className='small-table__no-data'>(no data)</h2>
                    : <div className='small-table__spinner-wrapper'>
                        <Spinner spinnerSize={75} className='small-table__spinner'/>
                      </div>
                  }
                </TabPanel>

                <TabPanel>
                  {tableData && streamUpdates
                    ? priceRateData.length > 0 
                      ? <ul className='small-table__table'>
                          {priceRateData.map((tableID, idx) => {
                            return (
                              <SmallTableItem 
                                key={idx} 
                                idx={idx} 
                                tableId={tableId}
                                dimWidth={dimWidth}
                                tableData={tableID}
                                streamUpdates={streamUpdates}
                                isRatesTable={isRatesTable}
                                setTableData={setTableData}
                                sendJsonMessage={sendJsonMessage}
                                chartsDataValues={chartsDataValues}
                              />
                            )
                          })}
                        </ul>
                      : <h2 className='small-table__no-data'>(no data)</h2>
                    : <div className='small-table__spinner-wrapper'>
                        <Spinner spinnerSize={75} className='small-table__spinner'/>
                      </div>
                  }
                </TabPanel>
              </>
            : 
              (
                <TabPanel>
                  {tableData && streamUpdates
                    ? indiceRateData.length > 0 
                      ? <ul className='small-table__table'>
                          {indiceRateData.map((tableData, idx) => {
                            return (
                              <SmallTableItem 
                                key={idx} 
                                idx={idx} 
                                tableId={tableId}
                                dimWidth={dimWidth}
                                tableData={tableData}
                                isRatesTable={isRatesTable}
                                setTableData={setTableData}
                                streamUpdates={streamUpdates}
                                sendJsonMessage={sendJsonMessage}
                                chartsDataValues={chartsDataValues}
                              />
                            )
                          })}
                        </ul>
                      : <h2 className='small-table__no-data'>(no data)</h2>
                    : <div className='small-table__spinner-wrapper'>
                        <Spinner spinnerSize={75} className='small-table__spinner'/>
                      </div>
                  }
                </TabPanel>
              )
          }
        </div>
      </Tabs>
    </div>
  )
}

export default SmallTable;
