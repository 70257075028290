const displayNumberWithDecimals = (p) => {
  if (p > 1000) {
    return Math.round(p);
  }
  if (p < 0.01) {
    const precision = 1 - Math.floor(Math.log(p) / Math.log(10));
    return p.toFixed(precision);
  }

  return p ? p.toFixed(2) : "-";
};

export default displayNumberWithDecimals;
