import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import cloneDeep from 'lodash.clonedeep';

import classModifier from '../../utils/classModifier';

import './Main.scss';
import Links from '../Links/Links';
import SlideText from '../SlideText/SlideText';
import MainTable from '../MainTable/MainTable';
import SmallTable from '../SmallTable/SmallTable';
import SmallTableTooltip from '../SmallTable/components/SmallTableTooltip';

const selectFirstGraph = () => {
  setTimeout(() => {
  // fixcacalol : select first table item
  document.querySelector(
    ".small-table__table>.small-table__table-row"
  )?.click();
  }, 0);
};

const Main = ({ dimensions }) => {

  const [referenceData, setReferenceData] = useState(null);
  const [streamUpdates, setStreamUpdates] = useState(null);
  const [socketUrl] = useState("wss://indices-dashboard.kaiko.io/v1/ws");
  // const [socketUrl] = useState("ws://127.0.0.1:7888/v1/ws");
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);
  const [ chartsDataValues, setChartsDataValues ] = useState({});

  const [tableItemData, setTableItemData] = useState({
    id: '',
    title: '',
  });

  useEffect(() => {
    if (lastJsonMessage === null) {
      sendJsonMessage({ action: "stream_updates" });
      sendJsonMessage({ action: "get_reference" });
      return;
    }

    const { action, data, params } = lastJsonMessage || {};

    if(action === 'get_reference') {
      setReferenceData(data);
    };

    if(action === 'get_historical_values') {
      const vals = cloneDeep(chartsDataValues);
      vals[params.id] = lastJsonMessage
      setChartsDataValues(vals);
    }

    if(action === 'stream_updates') {
      setStreamUpdates(data);
    };

    if (tableItemData.id === '') {
      selectFirstGraph();
    }
  }, [sendJsonMessage, lastJsonMessage, chartsDataValues, setChartsDataValues, tableItemData.id]);

  const indicesTableData = referenceData ? Object.entries(referenceData).filter(([_, { type }]) => type !== "indice") : null;
  const ratesTableData = referenceData ? Object.entries(referenceData).filter(([_, { type }]) => type === "indice") : null;

  return (
    <div className='main'>
      {dimensions.width <= 910 
        ? 
          <>
            <Tabs className="main__tabs">
              <div className="main__tabs-list-wrapper">
                <TabList className="main__tabs-list">
                  <Tab className="main__tabs-item">Rates</Tab>
                  <Tab className="main__tabs-item">Indices</Tab>
                  <Tab className="main__tabs-item">Assets</Tab>
                  <Tab className="main__tabs-item">News/links</Tab>
                </TabList>
              </div>

              <TabPanel>
                <SmallTable 
                  isRatesTable
                  tableTitle="rates table" 
                  tableData={ratesTableData}
                  dimWidth={dimensions.width}
                  tableId={tableItemData.id}
                  streamUpdates={streamUpdates}
                  setTableData={setTableItemData}
                  sendJsonMessage={sendJsonMessage}
                  chartsDataValues={chartsDataValues}
                />
              </TabPanel>

              <TabPanel>
                <SmallTable 
                  tableTitle="indices table" 
                  tableData={indicesTableData} 
                  dimWidth={dimensions.width}
                  tableId={tableItemData.id}
                  streamUpdates={streamUpdates}
                  setTableData={setTableItemData}
                  sendJsonMessage={sendJsonMessage}
                  chartsDataValues={chartsDataValues}
                />
              </TabPanel>

              <TabPanel>
                <MainTable refData={referenceData} dimWidth={dimensions.width}/>
              </TabPanel>

              <TabPanel> 
                <div className='main__info-tab-wrapper'>
                  <SlideText />
                  <Links />
                </div>
              </TabPanel>
            </Tabs>
          </>
        : <>
            <SlideText />

            <div className='main__tables'>
              <div className='main__aside-tables'>
                <SmallTable 
                  isRatesTable
                  tableTitle="rates table" 
                  tableData={ratesTableData}
                  dimWidth={dimensions.width}
                  tableId={tableItemData.id}
                  streamUpdates={streamUpdates}
                  setTableData={setTableItemData}
                  sendJsonMessage={sendJsonMessage}
                  chartsDataValues={chartsDataValues}
                />
                <SmallTable 
                  tableTitle="indices table" 
                  tableData={indicesTableData} 
                  dimWidth={dimensions.width}
                  tableId={tableItemData.id}
                  streamUpdates={streamUpdates}
                  setTableData={setTableItemData}
                  sendJsonMessage={sendJsonMessage}
                  chartsDataValues={chartsDataValues}
                />
              </div>

              <div className='main__main'>
                <div className={classModifier('small-table__popup-wrapper', 'main')}>
                  <SmallTableTooltip {...tableItemData}
                    chartsDataValues={chartsDataValues}
                  />
                </div>
                <Links />
              </div>
            </div> 
            <MainTable refData={referenceData} dimWidth={dimensions.width} />
          </>
      }
    </div>
  )
}

export default Main;
