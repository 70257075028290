import { ReactComponent as IconAggregated } from './aggregated.svg';
import { ReactComponent as IconArrowAway } from './arrow-away.svg';
import { ReactComponent as IconArrowRight } from './arrow-right.svg';
import { ReactComponent as IconArrowDown } from './arrow-down.svg';
import { ReactComponent as IconArrowFillDown } from './arrow-fill-down.svg';
import { ReactComponent as IconArrowFillUp } from './arrow-fill-up.svg';
import { ReactComponent as IconArrowLeftSmall } from './arrow-left-small.svg';
import { ReactComponent as IconArrowRightSmall } from './arrow-right-small.svg';
import { ReactComponent as IconArrowUp } from './arrow-up.svg';
import { ReactComponent as IconAsset } from './asset.svg';
import { ReactComponent as IconBitcoin } from './bitcoin.svg';
import { ReactComponent as IconBurger } from './burger.svg';
import { ReactComponent as IconCalendar } from './calendar.svg';
import { ReactComponent as IconChecked } from './checked.svg'; 
import { ReactComponent as IconClose } from './close.svg';
import { ReactComponent as IconCopy } from './copy.svg';
import { ReactComponent as IconCSV } from './csv.svg';
import { ReactComponent as IconExchange } from './exchange.svg';
import { ReactComponent as IconFB } from './fb.svg';
import { ReactComponent as IconFullScreen } from './full-screen.svg';
import { ReactComponent as IconGranular } from './granular.svg';
import { ReactComponent as IconHexagonLight } from './hexagon-light.svg';
import { ReactComponent as IconLinkedin } from './linkedin.svg';
import { ReactComponent as IconMail } from './mail.svg';
import { ReactComponent as IconPhoto } from './photo.svg';
import { ReactComponent as IconSearch } from './search.svg';
import { ReactComponent as IconShare } from './share.svg';
import { ReactComponent as IconSmallScreen } from './small-screen.svg';
import { ReactComponent as IconTwitter } from './twitter.svg';
import { ReactComponent as IconUnion } from './union.svg';
import { ReactComponent as IconUsdc } from './usdc.svg';
import { ReactComponent as IconViewFull } from './view-full.svg';
import { ReactComponent as IconViewSplit } from './view-split.svg';

const ICONS = {
  aggreagted: IconAggregated,
  arrowAway: IconArrowAway,
  arrowRight: IconArrowRight,
  arrowDown: IconArrowDown,
  arrowUp: IconArrowUp,
  arrowFillDown: IconArrowFillDown,
  arrowFillUp: IconArrowFillUp,
  arrowLeftSmall: IconArrowLeftSmall,
  arrowRightSmall: IconArrowRightSmall,
  asset: IconAsset,
  bitcoin: IconBitcoin,
  burger: IconBurger,
  calendar: IconCalendar,
  checked: IconChecked,
  close: IconClose,
  copy: IconCopy,
  csv: IconCSV,
  exchange: IconExchange,
  fb: IconFB,
  fullScreen: IconFullScreen,
  granular: IconGranular,
  hexagonLight: IconHexagonLight,
  linkedin: IconLinkedin,
  mail: IconMail,
  photo: IconPhoto,
  search: IconSearch,
  share: IconShare,
  smallScreen: IconSmallScreen,
  twitter: IconTwitter,
  union: IconUnion,
  usdc: IconUsdc,
  viewFull: IconViewFull,
  viewSplit: IconViewSplit,
};

export default ICONS;