import React, { useEffect, useState } from 'react';
import './App.scss';
import Header from '../Header/Header';
import Main from '../Main/Main';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const App = () => {
  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize);
  });

  return (
    <div className="app">
      <Header dimensions={dimensions}/>

      <main className='app__main'>
        <Main dimensions={dimensions}/>
      </main>
    </div>
  );
}

export default App;
