import React, { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import {isMobile} from 'react-device-detect';

import ICONS from '../../../assets/icons';
import classModifier from '../../../utils/classModifier';
import displayNumberWithDecimals from '../../../utils/displayNumberWithDecimals';
import SmallTableTooltip from './SmallTableTooltip';

const SmallTableItem = ({
  idx,
  tableId,
  dimWidth,
  tableData,
  isRatesTable,
  setTableData,
  streamUpdates = {},
  sendJsonMessage,
  chartsDataValues
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isChangeTooltipPosition = dimWidth <= 1300;

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
    getArrowProps,
  } = usePopperTooltip({
    trigger: 'click',
    placement: isChangeTooltipPosition ? 'auto-start' : 'right',
    visible: isTooltipOpen,
    onVisibleChange: setIsTooltipOpen
  });

  const [ticker, { symbol, name, composition_total }] = tableData;

  if(!streamUpdates || !streamUpdates[ticker]) {
    // return <h2 className='small-table__no-data'>(no data)</h2>;
    return null;
  }

  const { drpc, drvc, drivc, last_price, last_index_value } = streamUpdates[ticker];

  const DRPC = +drpc;
  const DRVC = +drvc;
  const DRIVC = +drivc;
  const LAST_PRICE = +last_price;
  const LAST_IDX_VALUE = +last_index_value;

  const isPositivePercentDrpc = isRatesTable ? +drpc > 0 : +drivc > 0;
  const isPositivePercentDrvc = +drvc > 0;
  const isOdd = idx%2 === 0;

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  }

  const handleTableItemSelect = () => {
    sendJsonMessage({ action: "get_historical_values", params: {id: ticker, interval: "1d"}});
    setIsTooltipOpen(!isTooltipOpen);
    setTableData({
      id: ticker,
      title: name
    });
  }

  const value = displayNumberWithDecimals(isRatesTable ? LAST_PRICE : LAST_IDX_VALUE);

  return (
    <>
      {visible && isMobile &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps()}
          className='small-table__popup-wrapper'
        >
          <div {...getArrowProps({ className: 'small-table__popup-arrow' })} />
          <SmallTableTooltip
            id={ticker}
            title={ticker}
            onClose={handleCloseTooltip}
            chartsDataValues={chartsDataValues}
          />
        </div>
      }

      <li
        onClick={handleTableItemSelect}
        className={classModifier('small-table__table-row',
          ['body', isOdd && 'is-odd', tableId === ticker && 'is-popup-open'])}
      >
        <div ref={setTriggerRef} className='small-table__popup-chart-tooltip-trigger'/>

        <div className={classModifier('small-table__table-col', ['ticker', 'body'])}>
          <img src={symbol} alt='alt' width={16} height={16}/>
          <span title={ticker}>{ticker}</span>
        </div>
        <div className={classModifier('small-table__table-col', 'value')}>
          {value}
        </div>

        <div
          style={{ color: isPositivePercentDrpc ? '#10D096' : '#FF316A'}}
          className={classModifier('small-table__table-col', ['growth', 'body-percent'])}
        >
          {isPositivePercentDrpc
            ? <ICONS.arrowUp width={10} height={10}/>
            : <ICONS.arrowDown width={10} height={10}/>
          }
          {isRatesTable ? (DRPC*100).toFixed(2) : (DRIVC*100).toFixed(2)}%
        </div>

        {isRatesTable && false &&
          <div
            style={{ color: isPositivePercentDrvc ? '#10D096' : '#FF316A'}}
            className={classModifier('small-table__table-col', ['adtv', 'body-percent'])}
          >
            {isPositivePercentDrvc
              ? <ICONS.arrowUp width={10} height={10}/>
              : <ICONS.arrowDown width={10} height={10}/>
            }
            {(DRVC*100).toFixed(2)}%
          </div>
        }

        <div className={classModifier('small-table__table-col', ['exchange', 'exchange-body'])}>
          {composition_total}
        </div>
      </li>
    </>
  )
}

export default SmallTableItem;
