import React, { useMemo, useState } from 'react';
import classModifier from '../../utils/classModifier';
import { usePopperTooltip } from 'react-popper-tooltip';

const rateValue = {
  reference: 'Reference Rate',
  benchmark_reference: 'Benchmark Reference Rate',
  'Multi Indeces': 'Blue-Chip',
  'multi_indeces': 'Blue-Chip',
  'Blue-Chip': 'Blue-Chip',
}

const MainTableItem = ({ itemProps, idx, dimWidth }) => {
  const { symbol, bases, quotes, rate, dissemination, name, inception_date, type } = itemProps;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: dimWidth <= 910 ? 'click' : 'hover',
    placement: dimWidth <= 910 ? 'bottom' : 'top',
    visible: isTooltipOpen,
    onVisibleChange: setIsTooltipOpen
  });

  const isEven = idx%2 === 0;

  const arrayDataForToooltip = useMemo(() => {
    const slicedArray = [
      {
        label: 'Type',
        value: rate,
        className: 'type'
      },
      {
        label: 'Quote Asset',
        value: quotes,
        className: 'quote-asset'
      },
      {
        label: 'Dissemination',
        value: dissemination,
        className: 'dissemination'
      },
      {
        label: 'Short Name',
        value: name,
        className: 'short-name'
      },
      {
        label: 'Inception date',
        value: inception_date.label,
        className: 'inception-date'
      },
    ]
    return slicedArray;
  }, [dissemination, inception_date.label, name, quotes, rate]);

  return (
    <>
      {visible && dimWidth <= 1430 &&
        <div 
          ref={setTooltipRef}
          {...getTooltipProps()}
          className='main-table__popup-wrapper'
        >
          <div className='main-table__popup-wrapper-mobile'>
            {arrayDataForToooltip.map(item => {
              return(
                <div key={item.label} className={classModifier('main-table__popup-item', item.className)}>          
                  <div className='main-table__popup-title'>{item.label}</div>
                  <div className='main-table__popup-value'>{item.value}</div>
                </div>
              )
            })}
          </div>
        </div>
      }
      <li 
        className={classModifier('main-table__table-row', ['body', isEven ? 'is-even' : 'is-odd', visible && 'is-visible'])} 
        onClick={() => setIsTooltipOpen(!isTooltipOpen)}
        ref={setTriggerRef}
      >
        <div className={classModifier('main-table__table-col', ['symbol', 'body'] )}>
          <img src={symbol} alt='logo' width={20} height={20 }/>
        </div>

        <div title={bases} className={classModifier('main-table__table-col', ['base-asset', 'body'] )}>
          {bases}
        </div>

        <div className={classModifier('main-table__table-col', ['quote-asset', 'body'] )}>
          {quotes}
        </div>

        <div className={classModifier('main-table__table-col', ['type', 'body'] )}>
          {rateValue[rate] || rateValue[type]}
        </div>

        <div className={classModifier('main-table__table-col', ['dissemination', 'body'])}>
          {dissemination}
        </div>

        <div className={classModifier('main-table__table-col', ['short-name', 'body'])}>
          {name}
        </div>

        <div className={classModifier('main-table__table-col', ['inception-date', 'body'])}>
          {inception_date.label}
        </div>
      </li>
    </>
  );  
};

export default MainTableItem;