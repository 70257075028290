import React, { useEffect, useState } from 'react';

import ICONS from '../../assets/icons';
import logo from '../../assets/img/logo.png';
import logoMobile from '../../assets/img/logo-mobile-svg.png';
import classModifier from '../../utils/classModifier';

import './Header.scss';

const Header = ({ dimensions }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = menuIsOpen ? 'hidden' : '';
    window?.scrollTo(0, 0);
  }, [menuIsOpen]);

  const handleBurgerMenuOpen = () => {
    setMenuIsOpen(!menuIsOpen);
  }

  const currLogo = dimensions?.width <= 1145 ? logoMobile : logo;

  return (
    <header className='header'>
      <h1 className='header__logo'>
        <a href='https://www.kaiko.com/'>
          <img className='header__logo-img' src={currLogo} alt='logo'/>
        </a>
      </h1>

      <div className={classModifier('header__nav-wrapper', menuIsOpen && 'is-open')}>
        {/* <nav className={classModifier('header__nav', menuIsOpen && 'is-open')}>
          <ul className={classModifier('header__menu', menuIsOpen && 'is-open')}>

            <li className={classModifier('header__menu-item', menuIsOpen && 'is-open')}>
              <a href='https://research.kaiko.com/insights'>
                <span>Insights</span>
              </a>
              <span></span>
            </li>

            <li className={classModifier('header__menu-item', menuIsOpen && 'is-open')}>
              <a href='https://research.kaiko.com/reports'>
                <span>Reports</span>
              </a>
            </li>

            <li className={classModifier('header__menu-item', menuIsOpen && 'is-open')}>
              <a href='https://research.kaiko.com/medias'>
                <span>Analyst Calls</span>
              </a>
            </li>

            <li className={classModifier('header__menu-item', menuIsOpen && 'is-open')}>
              <a href='https://research.kaiko.com/contact-research'>
                <span>Contact</span>
              </a>
            </li>

          </ul>
        </nav> */}

        <div className='header__side-nav'>
          {/* <a href="/" className='header__btn heaer_btn--main'>
            Data +
            <ICONS.arrowRight />
          </a> */}

          <a href="https://www.kaiko.com/" className='header__btn'>
            Kaiko.com

            <ICONS.arrowRight />
          </a>
        </div>
      </div>

      <button className={classModifier('header__btn-burger', menuIsOpen && 'is-open')} onClick={handleBurgerMenuOpen}>
        <span></span>
        <span></span>
        <span></span>
      </button>

    </header>
  )
};

export default Header;
