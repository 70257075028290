import React, { useCallback, useEffect, useState } from 'react';

import ICONS from '../../assets/icons';
import classModifier from '../../utils/classModifier';

import './MainTable.scss';
import MainTableItem from './MainTableItem';
import Spinner from '../Spinner/Spinner';

const formatType = {
  "multi_indices": "Blue-Chip",
  reference: 'Reference Rate',
  "benchmark_reference": 'Benchmark Reference Rate',
}

const formatData = data => new Date(data).toLocaleDateString('default', { 
  month: 'long', 
  day: 'numeric', 
  year: 'numeric' 
});

const MainTable = ({ dimWidth, refData }) => {
  const [search, setSearch] = useState('');
  const [rateFlag, setRateFlag] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortState, setSortState] = useState({ colName: null, type: null });

  const pagMaxItems = 10;
  const initialFormattedData = Object
    .entries(refData || {})
    .map(([id_name, { bases, quotes, dissemination, inception_date, ...rest }]) => {
      return [id_name, {
        ...rest,
        bases: bases.join().toUpperCase(),
        quotes: [...new Set(quotes)].join().toUpperCase(),
        dissemination,
        inception_date: +new Date(inception_date)
      }];
    });

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  }

  const handleSelectPagination = (k, maxPages) => {
    if(currentPage + k > maxPages) {
      setCurrentPage(1);
      return;
    }

    if(currentPage + k < 1) {
      setCurrentPage(maxPages);
      return;
    }

    setCurrentPage(currentPage + k);
  };

  const handleFilterByRate = (flag) => {
    setRateFlag(flag === rateFlag ? '' : flag);
  }

  const handleSortTable = (colName) => {
    if(sortState.type === 'desc') {
      setSortState({ colName: null, type: null });
      return;
    }

    setSortState({
      colName,
      type: !sortState.type ? 'asc' : sortState.type === 'desc' ? null : 'desc'
    });
  };

  const handleFilters = useCallback(() => {
    // search filtration

    const filteredData = initialFormattedData
      .map(item => {
        const [id, data] = item;
        const newItem = [
          id, 
          { ...data, 
            inception_date: {
              label: formatData(data.inception_date),
              value: data.inception_date
            },
            rate: data.rate || formatType[data.type] ,
            rateAdded: formatType[data.type] || formatType[data.rate]
          }
        ];
        return newItem;
      })
      .filter(([_, { symbol, type, launch_date, inception_date, ...rest }]) => {

        // console.log('dsf', rest);
        return Object.values(rest).join(' ').toUpperCase().includes(search.toUpperCase())
      });

    // main table filtration

    const sortedAllData = filteredData.sort((a, b) => {
      const [, itemA] = a;
      const [, itemB] = b;

      switch(sortState.colName) {
        case 'base-asset': {
          const turn = sortState.type === 'asc'
            ? itemA.bases < itemB.bases
            : itemA.bases > itemB.bases;

          return turn ? 1 : -1;
        };

        case 'dissemination': {
          const turn = sortState.type === 'asc'
            ? itemA.dissemination < itemB.dissemination
            : itemA.dissemination > itemB.dissemination;

          return turn ? 1 : -1;
        };

        case 'quote-asset': {
          const turn = sortState.type === 'asc'
            ? itemA.quotes < itemB.quotes
            : itemA.quotes > itemB.quotes;

          return turn ? 1 : -1;
        };

        case 'short-name': {
          const turn = sortState.type === 'asc'
            ? itemA.name < itemB.name
            : itemA.name > itemB.name;

          return turn ? 1 : -1;
        };

        case 'inception-date': {
          const turn = sortState.type === 'asc'
            ? itemA.inception_date.value < itemB.inception_date.value
            : itemA.inception_date.value > itemB.inception_date.value;

          return turn ? 1 : -1;
        };

        case 'type': {
          const turn = sortState.type === 'asc'
            ? itemA.rate < itemB.rate
            : itemA.rate > itemB.rate;

          return turn ? 1 : -1;
        };
        default: return 0;
      }
    });

    // rate filtration

    const filterByRate = rateFlag 
      ? sortedAllData.filter(([ idName, { type } ]) => {
          switch(rateFlag) {
            case 'pr': {
              return idName.toUpperCase().startsWith('KK_RFR')
            }
            case 'rr': {
              return idName.toUpperCase().startsWith('KK_BRR')
            }
            case 'bci': {
              return type === "multi_indices"
            }
            default: return false;
          }
      })
      : sortedAllData;

    const maxPages = Math.ceil(filterByRate.length / pagMaxItems);

    return [filterByRate, maxPages];
  }, [initialFormattedData, search, sortState, rateFlag]);

  const [filteredData, maxPages] = handleFilters();

  useEffect(() => {
    if(currentPage > maxPages) {
      setCurrentPage(1);
    }    
  }, [maxPages, currentPage]);

  if(!refData) {
    return (
      <div className='main-table'>
        <Spinner spinnerSize={90} className='main-table__spinner'/>
      </div>
    );
  }

  return (
    <div className='main-table'>
      <div className='main-table__header'>
        <div className='main-table__filtration'>
          <div className='main-table__search'>
            <ICONS.search className={classModifier('main-table__icon', 'search')}/>
            <input 
              type='text' 
              value={search}
              onChange={handleSearch}
              placeholder='Search in table'
              className='main-table__input' 
            />
          </div>
          
          <div className='main-table__btns'>
            <button 
              className={classModifier('main-table__btn', [rateFlag === 'pr' && 'active', 'filtration'])}
              onClick={() => handleFilterByRate('pr')}
            >
              Reference rate
            </button>

            <button 
              className={classModifier('main-table__btn', [rateFlag === 'rr' && 'active', 'filtration'])}
              onClick={() => handleFilterByRate('rr')}
            >
              Benchmark Reference rate
            </button>

            <button 
              className={classModifier('main-table__btn', [rateFlag === 'bci' && 'active', 'filtration'])}
              onClick={() => handleFilterByRate('bci')}
            >
              Blue-Chip Indices
            </button>
          </div>

        </div>

        <div className='main-table__pagination'>
          <button 
            className='main-table__btn'
            onClick={() => handleSelectPagination(-1, maxPages)}
          >
            <ICONS.arrowLeftSmall className={classModifier('main-table__icon', 'arrow')}/>
          </button>

          Page {currentPage} of {maxPages}

          <button 
            className='main-table__btn'
            onClick={() => handleSelectPagination(1, maxPages)}
          >
            <ICONS.arrowRightSmall className={classModifier('main-table__icon', 'arrow')}/>
          </button>
        </div>
      </div>

      <div className='main-table__table-wrapper'>
        <div className={classModifier('main-table__table-row', 'header')}>
          <div className={classModifier('main-table__table-col', 'symbol')}>Symbol</div>
          <div className={classModifier('main-table__table-col', 'base-asset')} onClick={() => handleSortTable('base-asset')}>
            Base Asset <span>(short)</span>
            <div className='main-table__sort-icons'>
              <ICONS.arrowFillUp className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'base-asset' && sortState.type === 'asc' && 'is-sorted'])}/>
              <ICONS.arrowFillDown className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'base-asset' && sortState.type === 'desc' && 'is-sorted'])}/>
            </div>
          </div>
          <div className={classModifier('main-table__table-col', 'quote-asset')} onClick={() => handleSortTable('quote-asset')}>
            Quote Asset <span>(short)</span>
            <div className='main-table__sort-icons'>
              <ICONS.arrowFillUp className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'quote-asset' && sortState.type === 'asc' && 'is-sorted'])}/>
              <ICONS.arrowFillDown className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'quote-asset' && sortState.type === 'desc' && 'is-sorted'])}/>
            </div>
          </div>
          <div className={classModifier('main-table__table-col', 'type')} onClick={() => handleSortTable('type')}>
            Family
            <div className='main-table__sort-icons'>
              <ICONS.arrowFillUp className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'type' && sortState.type === 'asc' && 'is-sorted'])}/>
              <ICONS.arrowFillDown className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'type' && sortState.type === 'desc' && 'is-sorted'])}/>
            </div>
          </div>
          <div className={classModifier('main-table__table-col', 'dissemination')} onClick={() => handleSortTable('dissemination')}>
            Dissemination
            <div className='main-table__sort-icons'>
              <ICONS.arrowFillUp className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'dissemination' && sortState.type === 'asc' && 'is-sorted'])}/>
              <ICONS.arrowFillDown className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'dissemination' && sortState.type === 'desc' && 'is-sorted'])}/>
            </div>
          </div>
          <div className={classModifier('main-table__table-col', 'short-name')} onClick={() => handleSortTable('short-name')}>
            Short Name
            <div className='main-table__sort-icons'>
              <ICONS.arrowFillUp className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'short-name' && sortState.type === 'asc' && 'is-sorted'])}/>
              <ICONS.arrowFillDown className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'short-name' && sortState.type === 'desc' && 'is-sorted'])}/>
            </div>
          </div>
          <div className={classModifier('main-table__table-col', 'inception-date')} onClick={() => handleSortTable('inception-date')}>
            Inception date
            <div className='main-table__sort-icons'>
              <ICONS.arrowFillUp className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'inception-date' && sortState.type === 'asc' && 'is-sorted'])}/>
              <ICONS.arrowFillDown className={classModifier('main-table__icon', 
                ['sort', sortState.colName === 'inception-date' && sortState.type === 'desc' && 'is-sorted'])}/>
            </div>
          </div>
        </div>

        <ul className='main-table__table'>
          {filteredData
            .slice(
                pagMaxItems * currentPage - pagMaxItems, 
                pagMaxItems * currentPage
              )
            .map(([id_name, itemProps], idx) => {
              return (
                <MainTableItem 
                  idx={idx}
                  key={id_name}
                  dimWidth={dimWidth}
                  itemProps={itemProps}
                />
              )
          })}
        </ul>
      </div>
    </div>
  )
}
export default MainTable;