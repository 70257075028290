import React, { useRef } from 'react';
import ReactEcharts from 'echarts-for-react';

import ICONS from '../../../assets/icons';
import logoMobile from '../../../assets/img/logo-popup.png';
import displayNumberWithDecimals from '../../../utils/displayNumberWithDecimals';

const SmallTableTooltip = ({ id, title, onClose, chartsDataValues }) => {
  const eChartsRef = useRef();

  if (!id || !chartsDataValues || !(id in (chartsDataValues || {}))) {
    return '';
    // return 'Please click an item to display graph';
  }

  if (!chartsDataValues[id].data.entries) {
    return '';
    // return 'Please click an item to display graph';
  }

  const currData = chartsDataValues[id].data.entries;

  const seriesData = currData.map(dataItem => displayNumberWithDecimals(parseFloat(dataItem.value)));
  const xAxisData = currData.map(dataItem => new Date(dataItem.datetime).toLocaleDateString('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }));

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'none' },
      className: 'small-table__popup-chart-tooltip',
      position: 'inside',
      backgroundColor: '#000',
      borderWidth: 0,
      textStyle: { color: '#EEE' },
      formatter: '<div class="small-table__popup-chart-tooltip-content">{b0}: <span>{c0}</span>',
    },
    xAxis: [{
      type: 'category',
      boundaryGap: false,
      axisLine : { show: false },
      splitLine: { show: false },
      axisLabel: {
        color: '#7F7FA2',
        fontWeight: 'bold',
      },
      verticalAlign: 'bottom',
      data: xAxisData
    }],
    grid: {
      left: '0%',
      right: '1%',
      bottom: '2%',
      containLabel: true
    },
    yAxis: [{
      type: 'value',
      show: true,
      min: Math.min(...seriesData),
      splitLine: {
        show: false
      }
    }],
    series: [
      {
        type: 'line',
        data: seriesData,
        // symbol: <ICONS.bitcoin />,
        showSymbol: false,
        symbolSize: 7,
        lineStyle: {
          width: 4,
          color: '#0067FB'
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0.01,
                color: '#000046',
              },
              {
                offset: 1,
                color: '#000046c2',
              }
            ],
            global: false
          },
        },
        smooth: true
      }
    ]
  };

  return (
    <div className='small-table__popup'>
      <div className='small-table__popup-header'>
        <h3 className='small-table__popup-title'>{title}</h3>
        <div className='small-table__popup-header-content'>
          <h4 className='small-table__popup-logo'>
            powered by
            <img src={logoMobile} alt='logo'/>
          </h4>

          {onClose &&
            <button
              onClick={onClose}
              className='small-table__popup-btn'
            >
              <ICONS.hexagonLight />
            </button>
          }
        </div>
      </div>

      <div className='small-table__popup-chart'>
        <ReactEcharts
          ref={eChartsRef}
          option={option}
          style={{ width: '100%', height: '100%', }}
        />
      </div>


    </div>
  )
}

export default SmallTableTooltip;
